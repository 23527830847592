.sc-gKHVLF{
  display:none
} 
.login {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .login .div {
    background-color: #ffffff;
    border: 1px none;
    height: 100%;
    position: relative;
    width: 100%;
  }
  
  .login .group {
    /* height: 629px; */
    left: 10%;
    position: absolute;
    top: 100px;
    width: 503px;
  }
  
  .login .text-wrapper {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 68px;
    white-space: nowrap;
  }

  .login .text-wrapper-sign {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 30px;
    white-space: nowrap;
  }
  
  .login .p {
    color: #adadad;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 52px;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 113px;
    width: 290px;
  }
  
  .login .h-1 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 27px;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .login .group-2 {
    /* height: 422px; */
    left: 0;
    position: absolute;
    top: 207px;
    width: 499px;
  }

  .login .group-2_sign {
    /* height: 422px; */
    left: 0;
    position: relative;
    top: 100px;
    width: 499px;
  }
  
  .login .input-filled {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 92px;
    width: 497px;
  }

  .login .input-filled_sign {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 220px;
    width: 497px;
  }
  
  .login .overlap1 {
    height: 50px;
    position: relative;
    width: 500px;
  }

  .login .overlap2 {
    height: 60px;
    position: relative;
    width: 500px;
  }

  .login .overlap3 {
    height: 90px;
    position: relative;
    width: 500px;
  }
  
  .login .input-BG {
    border-radius: 8px;
    box-shadow: var(--nav-bot);
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 300px;
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
  }
  
  .login .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 25px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 10px;
    width: 475px;
  }
  
  .login .feather-icon-eye-off {
    height: 24px;
    left: 457px;
    position: absolute;
    top: 15px;
    width: 24px;
  }
  
  .login .shape {
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }
  
  .login .img {
    height: 18px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 24px;
  }
  
  .login .size {
    height: 24px;
    /* left: 5838px; */
    position: absolute;
    /* top: 1076px; */
    width: 24px;
  }
  
  .login .overlap-wrapper {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 497px;
  }

  .login .overlap-wrapper1 {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 497px;
  }
  .login .overlap-wrapper2 {
    /* background-color: #ffffff; */
    /* border: 1px solid; */
    border-color: #e0e4e6;
    border-radius: 6px;
    height: 50px;
    left: 0;
    position: relative;
    top: 0;
    width: 497px;
  }
  
  .login .primary {
    background-color: #7c3a8e;
    border-radius: 6px;
    box-shadow: var(--dropshadow-1);
    height: 50px;
    left: 0;
    position: absolute;
    top: 220px;
    width: 497px;
  }
  
  .login .continue {
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    left: 70px;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    top: 175px;
    width: 150px;
    border-radius: 5px;
  }
  .login .continue_sign {
    color: #ffffff;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    left: 70px;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-align: center;
    top: 75px;
    width: 150px;
    border-radius: 5px;
  }
  
  .login .text-wrapper-2 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 40px;
    left: 36px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 240px;
    width: 180px;
  }

  .login .text-wrapper-5 {
    color: #333333;
    font-family: "Open Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 40px;
    left: 156px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 240px;
    width: 180px;
    cursor: pointer;
  }
  
  .login .element-forms-elements {
    height: 24px;
    left: 0;
    position: absolute;
    top: 240px;
    width: 24px;
  }
  
  .login .ic-check-box {
    height: 18px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 18px;
  }
  
  .login .left {
    /* height: 1080px; */
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }
  
  .login .frame {
    height: 135px;
    /* left: 55%; */
    position: absolute;
    /* top: -11px; */
    width: 200px;
  }

  .label {
    border: 0px none;
    height: 51px;
    width: 475px;
}

.label .placeholder {
    color: #9e9e9e;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 51px;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: fixed;
    top: 0;
    width: 475px;
}

.login .continue a{
  text-decoration: none;
  color:#ffffff;
}

/* Add margin to the web borders */
body {
  margin: 0;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* background-color: #f8f9fa; */
}

.div {
  position: relative;
  width: 100%;
  max-width: 360px;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  margin: 20px; /* Add margin here */
  top: 20px;
}

.signin_admin{
  position: relative;
  margin-top: 85px;
}

.resign_admin{
 cursor: pointer;
}
.sc-gKHVLF{
  display:none
}

.error_message{
  color: red;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh; 
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  overflow: hidden;
}

.chat-header {
  padding: 10px;
  background-color:  #25388D;
  color: white;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-message-user,
.chat-message-admin {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  /* max-width: 80%; */
}

.chat-message-user {
  background-color: #e1ffc7;
  align-self: flex-start;
}

.chat-message-admin {
  background-color: #d1d1d1;
  align-self: flex-end;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  background-color:  #25388D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color:  #25388D;
}


.chat-message-admin {
  text-align: right;
}

.chat-message-user {
  text-align: left;
}

.seats-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 seats per row */
  gap: 10px; /* Add some gap between seats */
  margin-top: 20px;
}

.seat {
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}


.container-list {
  /* display: flex; */
  flex-wrap: wrap;
  /* width: 61%; */
  /* margin-left: 20%; */
}

.container-item {
  margin: 20px;
}

.seats-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
}

.seat {
  padding: 10px;
  background-color: lightgray;
  border: 1px solid gray;
  text-align: center;
  cursor: pointer;
}

.seat.booked {
  background-color: red;
  cursor: not-allowed;
}

.seat.selected {
  background-color: green;
}

.booking-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.booking-summary p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.booking-summary button {
  background-color: #e50914; /* Red color like BookMyShow */
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.booking-summary button:hover {
  background-color: #b0070f; /* Darker red on hover */
}

.container-item {
  /* border: 1px solid #ddd; */
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.seats-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* Adjust the number based on how many seats per row */
  gap: 5px;
  margin-top: 10px;
}

.seat {
  width: 40px;
  height: 40px;
  background-color: #6c757d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.seat:hover {
  background-color: #5a6268;
}

.seat.selected {
  background-color: #28a745;
}

.seat.booked {
  background-color: #dc3545;
  cursor: not-allowed;
}

.booking-summary {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #ddd;
}

.booking-summary p {
  font-size: 18px;
  font-weight: bold;
}

.booking-summary button {
  background-color: #ff5a00;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.booking-summary button:hover {
  background-color: #e04d00;
}


.container-wrapper { 
  background-color: #f9f9f9;
  /* border-radius: 8px; */
  box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
  margin: 0 auto;
  padding: 20px;
  width: 80%;
  margin-left: 260px;
  margin-top: 62px;
}

h2, h3 {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-form {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.add-button, .submit-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.add-button:hover, .submit-button:hover {
  background-color: #0056b3;
}

.submit-button {
  margin-top: 30px;
  background-color: #28a745;
}

.submit-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .container-wrapper {
    width: 100%;
    padding: 10px;
  }

  .form-group {
    flex-direction: column;
  }
}

.seats-grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.seat-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row-label {
  font-weight: bold;
  margin-right: 10px;
}

.seat {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  cursor: pointer;
}

.seat.selected {
  background-color: green;
}

.seat.booked {
  background-color: red;
  cursor: not-allowed;
}

.seat.break {
  visibility: hidden; /* Hide the "break" seat */
  width: 40px; /* Maintain space for layout consistency */
}

.booking-summary {
  margin-top: 20px;
}




