 /* {
  background-color: #f9f9fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

 .div {
  background-color: #f9f9fc;
  border: 1px;
  height: 100%;
  position: relative;
  width: 100%;
}

 .header {
 background-color: #fff;
    box-shadow: 0 0 14px #3540520d;
    height: 64px;
    left: 15%;
    position: absolute;
    top: 0;
    width: 80%;
}


 .ic-error-outline {
  height: 24px;
  left: 89%;
  position: absolute;
  top: 24px;
  width: 24px;
}

 .group {
  height: 32px;
  left: 91%;
  position: absolute;
  top: 16px;
  width: 33px;
}

 .overlap-group {
  height: 32px;
  position: relative;
}

 .img {
  height: 24px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 24px;
}

 .element-status-indicators {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 14px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

 .button-text {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.14px;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

 .icon {
  height: 32px;
  left: 94%;
  position: absolute;
  top: 16px;
  width: 35px;
}

 .button-text-wrapper {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 90px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 16px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
}

 .element-avatars-circle {
  height: 64px;
  left: 97%;
  position: absolute;
  top: 0;
  width: 40px;
}

 .overlap {
  background-color: #f3f7fc;
  border-radius: 20px;
  height: 40px;
  position: relative;
  top: 12px;
}

 .text-wrapper {
  color: #4f2564;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 21px;
  left: 13px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

 .icon-2 {
  height: 24px;
  left: 92%;
  position: absolute;
  top: 20px;
  width: 24px;
}

 .icons {
  height: 24px;
  left: 24px;
  position: absolute;
  top: 20px;
  width: 120px;
}

 .text-wrapper-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 40px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

 .ic-search {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

 .overlap-2 {
    /* height: 698px; */
    left: 15%;
    position: relative;
    top: 282px;
    width: 79%;
}

 .footer {
  background-color: #ffffff;
  height: 64px;
  left: 10px;
  position: absolute;
  top: 317px;
  width: 100%;
}

 .support-help-center {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .group-2 {
  height: 637px;
  left: 24px;
  position: absolute;
  top: 0;
  /* width: 1616px; */
}

 .group-3 {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 310px;
  left: 0;
  position: absolute;
  top: 0;
  width: 836px;
}

 .overlap-3 {
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 836px;
}

 .element-cards-header {
  background-color: #ffffff;
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 836px;
}

 .card-title-change {
  color: #000000de;
  font-family: var(--h6-heading-font-family);
  font-size: var(--h6-heading-font-size);
  font-style: var(--h6-heading-font-style);
  font-weight: var(--h6-heading-font-weight);
  left: 16px;
  letter-spacing: var(--h6-heading-letter-spacing);
  line-height: var(--h6-heading-line-height);
  position: absolute;
  top: 15px;
  width: 329px;
}

 .feather-icon-chevron {
  height: 24px;
  left: 792px;
  position: absolute;
  top: 14px;
  width: 24px;
}

 .feather-icon-chevron-2 {
  height: 24px;
  left: 768px;
  position: absolute;
  top: 14px;
  width: 24px;
}

 .chart {
  height: 229px;
  left: 25px;
  overflow: hidden;
  position: absolute;
  top: 65px;
  width: 784px;
}

 .overlap-4 {
  height: 217px;
  left: -7px;
  position: relative;
  width: 791px;
}

 .y-value-labels {
  height: 180px;
  left: 0;
  opacity: 0.85;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 60px;
}

 .element {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

 .element-k {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 53px;
  white-space: nowrap;
}

 .element-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 107px;
  white-space: nowrap;
  width: 37px;
}

 .element-3 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 160px;
  white-space: nowrap;
  width: 34px;
}

 .text-wrapper-3 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 97px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 24px;
}

 .text-wrapper-4 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 155px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .text-wrapper-5 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 236px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .text-wrapper-6 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 315px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 73px;
}

 .text-wrapper-7 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 399px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .text-wrapper-8 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 481px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .text-wrapper-9 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 560px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .text-wrapper-10 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 657px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 32px;
}

 .text-wrapper-11 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 720px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 71px;
}

 .overlap-group-2 {
  height: 201px;
  left: 60px;
  position: absolute;
  top: 0;
  width: 731px;
}

 .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 191px;
  width: 731px;
}

 .line-2 {
  height: 201px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 1px;
}

 .bar {
  height: 185px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 13px;
}

 .bar-copy {
  height: 126px;
  left: 183px;
  position: absolute;
  top: 59px;
  width: 13px;
}

 .bar-copy-2 {
  height: 78px;
  left: 265px;
  position: absolute;
  top: 107px;
  width: 13px;
}

 .bar-copy-3 {
  height: 166px;
  left: 345px;
  position: absolute;
  top: 19px;
  width: 13px;
}

 .bar-copy-4 {
  height: 142px;
  left: 427px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-5 {
  height: 142px;
  left: 510px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-6 {
  height: 142px;
  left: 589px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-7 {
  height: 106px;
  left: 667px;
  position: absolute;
  top: 79px;
  width: 13px;
}

 .bar-copy-8 {
  height: 96px;
  left: 749px;
  position: absolute;
  top: 89px;
  width: 13px;
}

 .overlap-wrapper {
  height: 310px;
  left: 0;
  position: absolute;
  top: 326px;
  width: 1636px;
}

 .overlap-5 {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 310px;
  position: relative;
  /* width: 1616px; */
}

 .y-value-labels-2 {
  height: 180px;
  left: 24px;
  opacity: 0.85;
  overflow: hidden;
  position: absolute;
  top: 66px;
  width: 57px;
}

 .element-4 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: -1px;
  width: 45px;
}

 .element-5 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 53px;
  width: 34px;
}

 .element-6 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 106px;
  white-space: nowrap;
  width: 34px;
}

 .element-7 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 160px;
  white-space: nowrap;
  width: 34px;
}

 .jan {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 90px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 66px;
}

 .feb {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 237px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 67px;
}

 .mar {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 398px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 67px;
}

 .apr {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 559px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 70px;
}

 .may {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 723px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 74px;
}

 .jun {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 890px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 67px;
}

 .jul {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1052px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 79px;
}

 .aug {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1204px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 80px;
}

 .sep {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1358px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 67px;
}

 .sep-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1519px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 267px;
  width: 67px;
}

 .bar-2 {
  height: 185px;
  left: 116px;
  position: absolute;
  top: 66px;
  width: 17px;
}

 .bar-copy-9 {
  height: 126px;
  left: 424px;
  position: absolute;
  top: 125px;
  width: 17px;
}

 .bar-copy-10 {
  height: 126px;
  left: 263px;
  position: absolute;
  top: 125px;
  width: 17px;
}

 .bar-copy-11 {
  height: 78px;
  left: 586px;
  position: absolute;
  top: 173px;
  width: 17px;
}

 .bar-copy-12 {
  height: 166px;
  left: 752px;
  position: absolute;
  top: 85px;
  width: 17px;
}

 .bar-copy-13 {
  height: 142px;
  left: 916px;
  position: absolute;
  top: 109px;
  width: 17px;
}

 .bar-copy-14 {
  height: 65px;
  left: 1083px;
  position: absolute;
  top: 186px;
  width: 17px;
}

 .bar-copy-15 {
  height: 142px;
  left: 1236px;
  position: absolute;
  top: 109px;
  width: 17px;
}

 .bar-copy-16 {
  height: 106px;
  left: 1383px;
  position: absolute;
  top: 145px;
  width: 17px;
}

 .bar-copy-17 {
  height: 96px;
  left: 1544px;
  position: absolute;
  top: 155px;
  width: 17px;
}

 .overlap-group-3 {
  height: 267px;
  left: 0;
  position: absolute;
  top: 0;
  /* width: 1615px; */
}

 .line-3 {
  height: 202px;
  left: 86px;
  position: absolute;
  top: 65px;
  width: 1px;
}

 .line-4 {
  height: 1px;
  left: 63px;
  position: absolute;
  top: 256px;
  width: 1536px;
}

 .card-title-change-wrapper {
  background-color: #ffffff;
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1615px;
}

 .group-4 {
  height: 24px;
  left: 1548px;
  position: absolute;
  top: 14px;
  width: 48px;
}

 .group-5 {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 310px;
  left: 852px;
  position: absolute;
  top: 0;
  width: 764px;
}

 .overlap-group-wrapper {
  height: 229px;
  left: 19px;
  position: absolute;
  top: 65px;
  width: 722px;
}

 .overlap-6 {
  height: 217px;
  position: relative;
}

 .y-value-labels-3 {
  height: 180px;
  left: 0;
  opacity: 0.85;
  position: absolute;
  top: 0;
  width: 49px;
}

 .element-8 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 9px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: -1px;
  white-space: nowrap;
}

 .element-9 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 53px;
  white-space: nowrap;
}

 .element-10 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 106px;
  white-space: nowrap;
  width: 34px;
}

 .element-11 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 15px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: right;
  top: 160px;
  white-space: nowrap;
  width: 34px;
}

 .jan-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 61px;
}

 .feb-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 136px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .mar-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 211px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .apr-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 284px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 68px;
}

 .may-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 361px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .jun-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 437px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .jul-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 510px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .aug-2 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 583px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 61px;
}

 .sep-3 {
  color: #0000008a;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 657px;
  letter-spacing: 0.3px;
  line-height: 19px;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 65px;
}

 .overlap-group-4 {
  height: 201px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 673px;
}

 .line-5 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 191px;
  width: 673px;
}

 .line-6 {
  height: 201px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 1px;
}

 .bar-3 {
  height: 185px;
  left: 88px;
  position: absolute;
  top: 0;
  width: 13px;
}

 .bar-copy-18 {
  height: 126px;
  left: 162px;
  position: absolute;
  top: 59px;
  width: 13px;
}

 .bar-copy-19 {
  height: 78px;
  left: 237px;
  position: absolute;
  top: 107px;
  width: 13px;
}

 .bar-copy-20 {
  height: 166px;
  left: 311px;
  position: absolute;
  top: 19px;
  width: 13px;
}

 .bar-copy-21 {
  height: 142px;
  left: 387px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-22 {
  height: 142px;
  left: 463px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-23 {
  height: 142px;
  left: 536px;
  position: absolute;
  top: 43px;
  width: 13px;
}

 .bar-copy-24 {
  height: 106px;
  left: 607px;
  position: absolute;
  top: 79px;
  width: 13px;
}

 .bar-copy-25 {
  height: 96px;
  left: 683px;
  position: absolute;
  top: 89px;
  width: 13px;
}

 .overlap-7 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 764px;
}

 .p-wrapper {
  background-color: #ffffff;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 764px;
}

 .feather-icon-chevron-3 {
  height: 24px;
  left: 720px;
  position: absolute;
  top: 14px;
  width: 24px;
}

 .feather-icon-chevron-4 {
  height: 24px;
  left: 696px;
  position: absolute;
  top: 14px;
  width: 24px;
}

 .div-2 {
  height: 125%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 20%;
}

 .overlap-8 {
  background-color: #4f2564;
  height: 125%;
  left: 0;
  position: absolute;
  top: 64px;
  width: 260px;
}

 .element-navigations-menus {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

 .element-navigations-menus-2 {
  height: 44px;
  position: relative;
  width: 198px;
}

 .text-wrapper-12 {
  color: #eeeeee;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

 .feather-icon-layout-instance {
  height: 20px !important;
  left: 24px !important;
  top: 12px !important;
  width: 20px !important;
}

 .overlap-9 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 88px;
  width: 260px;
}

 .div-wrapper {
  height: 44px;
  position: relative;
  top: -88px;
}

 .overlap-group-5 {
  height: 44px;
  position: relative;
  width: 260px;
}

 .list-item-wrapper {
  /* background-color: #7c3a8e; */
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

 .list-item {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0.08px;
  line-height: 21px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
}

 .img-2 {
  height: 20px;
  left: 24px;
  position: absolute;
  top: 12px;
  width: 20px;
}

 .element-navigations-menus-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 198px;
}

 .feather-icon-chevron-5 {
  height: 24px;
  left: 198px;
  position: absolute;
  top: 10px;
  width: 24px;
}

 .overlap-10 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 260px;
}

 .element-navigations-menus-4 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 132px;
  width: 260px;
}

 .element-navigations-menus-5 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 260px;
}
 .element-navigations-menus-6 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 219px;
  width: 260px;
}

.element-navigations-menus-7 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 262px;
  width: 260px;
}

.element-navigations-menus-8 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 305px;
  width: 260px;
}
.element-navigations-menus-9 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 348px;
  width: 260px;
}

.element-navigations-menus-10 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 391px;
  width: 260px;
}
.element-navigations-menus-11 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 430px;
  width: 260px;
}

.element-navigations-menus-12 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 473px;
  width: 260px;
}

.element-navigations-menus-13 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 512px;
  width: 260px;
}

.element-navigations-menus-14 {
  height: 44px; 
  left: 0;
  position: absolute;
  top: 550px;
  width: 260px;
}
 
.element-navigations-menus-15 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 588px;
  width: 260px;
}

.element-navigations-menus-16 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 626px;
  width: 260px;
}

.element-navigations-menus-17 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 664px;
  width: 260px;
}


.element-navigations-menus-18 {
  height: 44px;
  left: 0;
  position: absolute;
  top: 702px;
  width: 260px;
}


 .frame-wrapper {
  background-color: #4f2564;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

 .frame {
  height: 39px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}

 .element-navigations-wrapper {
  height: 21px;
  left: 284px;
  position: absolute;
  top: 15px;
  width: 69px;
}

 .element-navigations {
  align-items: center;
  display: inline-flex;
  position: relative;
}

 .link {
  color: #0000008a;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

 .overlap-11 {
  height: 66px;
  left: 284px;
  position: absolute;
  top: 130px;
  width: 100%;
}

 .group-6 {
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 629px;
}

 .overlap-12 {
  background-color: #7c3a8e;
  height: 66px;
  left: 0;
  position: absolute;
  top: 0;
  width: 305px;
}

 .table-header {
  color: #ffffff;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .img-3 {
  height: 36px;
  left: 24px;
  position: absolute;
  top: 15px;
  width: 36px;
}

 .table-header-2 {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 379px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .element-status-indicators-2 {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 485px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

/*  .glyph-mail-trash {
  height: 36px;
  left: 329px;
  position: absolute;
  top: 15px;
  width: 36px;
} */

 .group-7 {
  height: 66px;
  left: 625px;
  position: absolute;
  top: 0;
  width: 919px;
}

 .element-status-indicators-3 {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 52px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 452px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
}

 .table-header-3 {
  color: #4f2564;
  font-family: var(--h4-heading-font-family);
  font-size: var(--h4-heading-font-size);
  font-style: var(--h4-heading-font-style);
  font-weight: var(--h4-heading-font-weight);
  left: 74px;
  letter-spacing: var(--h4-heading-letter-spacing);
  line-height: var(--h4-heading-line-height);
  position: absolute;
  top: 21px;
  white-space: nowrap;
}

 .group-8 {
  height: 66px;
  left: 610px;
  position: absolute;
  top: 0;
  width: 307px;
}

 .element-status-indicators-4 {
  align-items: center;
  background-color: #7c3a8e;
  border-radius: 52px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 165px;
  padding: 4px 6px;
  position: absolute;
  top: 23px;
  width: 39px;
}

/*  .rectangle {
  background-color: #7c3a8e;
  height: 4px;
  left: 284px;
  position: absolute;
  top: 196px;
  width: 83%;
} */

 .group-9 {
  height: 142px;
  left: 284px;
  position: absolute;
  top: 112px;
  width: 79%;
}

.group-9 .col-md-4 {
  width:33.3%
}

 .group-10 {
  height: 142px;
  /* left: 22.5%; */
  /* position: absolute; */
  top: 0;
  /* width: 21.5%; */
}

 .overlap-group-6 {
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #3540520d;
  height: 100%;
  position: relative;
  width: 100%;
}

 .sales-today {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  /* position: absolute; */
  top: 16px;
  width: 109px;
  padding-top: 12px;
    margin-left: 16px;
}

 .overlap-group-7 {
  height: 56px;
    left: 123px;
    position: absolute;
    top: 61px;
    width: 176px;
}

 .sales-today-2 {
  color: var(--colors5success-green-500);
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 51px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  text-align: center;
  top: 33px;
  /* width: 62px;*/
}

 .sales-today-copy {
  color: #000000;
    font-family: "Nunito", Helvetica;
    font-size: 15px;
    font-weight: 600;
    left: 57px;
    letter-spacing: 0;
    line-height: 28.8px;
    position: absolute;
    text-align: center;
    top: 2px;
    /* width: 56px;*/
}

 .group-11 {
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 135px;
}

 .sales-today-3 {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 48px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  /* position: absolute; */
  text-align: center;
  top: 94px;
  /* width: 51px;*/
}

 .sales-today-4 {
  color: var(--colorsred-500);
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 283px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  text-align: center;
  top: 94px;
  width: 77px;
}

 .sales-today-copy-2 {
  color: #000000;
  font-family: "Nunito", Helvetica;
  font-size: 15px;
  font-weight: 600;
  left: 310px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 63px;
  /* width: 37px;*/
}

 .h-1 {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 15px;
  font-weight: 600;
  left: 51px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 63px;
  /* width: 56px;*/
}

 .group-12 {
  height: 100%;
  left: 0;
  /* position: absolute; */
  top: 0;
  /* width: 21.5%; */
}

 .sales-today-5 {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 17px;
  font-weight: 400;
  margin-left: 16px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: relative;
  /* margin-top: 16px; */
  padding-top: 12px;
}

 .sales-today-copy-3 {
  color: #000000;
  font-family: "Nunito", Helvetica;
  font-size: 15px;
  font-weight: 600;
  left: 61px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 2px;
}

 .sales-today-copy-4 {
  color: #000000;
  font-family: "Nunito", Helvetica;
  font-size: 15px;
  font-weight: 600;
  left: 314px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 63px;
  /* width: 19px;*/
}

 .sales-today-copy-5 {
  color: #000000de;
    font-family: "Nunito", Helvetica;
    font-size: 15px;
    font-weight: 600;
    left: 56px;
    letter-spacing: 0;
    line-height: 28.8px;
    /* position: absolute; */
    text-align: center;
    top: 63px;
    /* width: 37px; */
}

 .group-13 {
  height: 142px;
    /* left: 45%; */
    /* position: absolute; */
    top: 0;
    /* width: 21.5%; */
}

 .sales-today-6 {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  /* position: absolute; */
  top: 16px;
  width: 182px;
  padding-top: 12px;
  margin-left: 16px;
}

 .sales-today-7 {
  color: var(--colors5success-green-500);
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 50px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  text-align: center;
  top: 33px;
  /* width: 55px;*/
}

 .sales-today-copy-6 {
  color: #000000;
    font-family: "Nunito", Helvetica;
    font-size: 15px;
    font-weight: 600;
    left: 61px;
    letter-spacing: 0;
    line-height: 28.8px;
    position: absolute;
    text-align: center;
    top: 2px;
    width: 19px;
}

 .sales-today-8 {
  color: #000000de;
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 49px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  text-align: center;
  top: 94px;
  /* width: 151px;*/
}

 .sales-today-9 {
  color: var(--colorsred-500);
  font-family: "Nunito", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 299px;
  letter-spacing: 0.06px;
  line-height: 20.4px;
  position: absolute;
  text-align: center;
  top: 94px;
  /* width: 69px;*/
}

.dashboard_hash{
  position: relative;
}

.dashboard_layouts{
  display: flex;
}

.dash_charts{
  display: flex;
  margin-top: 20px;
}


.dash_chart1{
  margin-top: 20px;
}