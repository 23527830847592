.frame_img {
  height: 65px;
  left: 80px;
  position: absolute;
  top: 13px;
  width: 100px;
}
.active div{
    background-color: #a5a7a7; 
    color: #25388D;
 
  }
  .innerHeader {
    height: 66px;
    left: 205px;
    position: relative;
    width: 80%;
} 
  .rectangle {
    background-color: #a5a7a7;
    height: 4px;
    left: 284px;
    position: absolute;
    top: 84px;
    width: 78%;
    color: #25388D;
}
  .group-ih-2 {
    /* height: 637px; */
    /* left: 24px; */
    position: absolute;
    top: 0;
    width: 100%;
  }
  .group-ih-2 .col-md-2{
    width:20% !important;
  }
  .group-ih-3 {
    height: 66px;
    left: 625px;
    position: absolute;
    top: 0;
    width: 919px;
  }
  .in-header {
    color:  #25388D;
    font-family: var(--h4-heading-font-family);
    font-size: var(--h4-heading-font-size);
    font-style: var(--h4-heading-font-style);
    font-weight: var(--h4-heading-font-weight);
    left: 61px;
    letter-spacing: var(--h4-heading-letter-spacing);
    line-height: var(--h4-heading-line-height);
    position: absolute;
    top: 21px;
    white-space: nowrap;
  }
  .in-header-2 {
    color:  #25388D;
    font-family: var(--h4-heading-font-family);
    font-size: var(--h4-heading-font-size);
    font-style: var(--h4-heading-font-style);
    font-weight: var(--h4-heading-font-weight);
    left: 74px;
    letter-spacing: var(--h4-heading-letter-spacing);
    line-height: var(--h4-heading-line-height);
    position: absolute;
    top: 21px;
    white-space: nowrap;
  }
  .in-header-3 {
    color: #a5a7a7;
    font-family: var(--h4-heading-font-family);
    font-size: var(--h4-heading-font-size);
    font-style: var(--h4-heading-font-style);
    font-weight: var(--h4-heading-font-weight);
    left: 379px;
    letter-spacing: var(--h4-heading-letter-spacing);
    line-height: var(--h4-heading-line-height);
    position: absolute;
    top: 21px;
    white-space: nowrap;
    color: #25388D;
  }
  .in-wrapper {
    align-items: center;
    background-color:  #25388D;
    border-radius: 52px;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    left: 195px;
    padding: 4px 6px;
    position: absolute;
    top: 23px;
  }
  .in-element-status-indicators-2 {
    align-items: center;
    background-color: #a5a7a7;
    border-radius: 52px;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    left: 113px;
    padding: 4px 6px;
    position: absolute;
    top: 23px;
    color: #25388D;
  }
  .in-overlap-group-wrapper {
    height: 66px;
    left: 610px;
    position: absolute;
    top: 0;
    width: 307px;
  }
  .in-overlap-group-3 {
    background-color: #a5a7a7;
    height: 66px;
    position: relative;
    width: 305px;
    color: #25388D;
  }
  .in-table-header-4 {
    color: #ffffff;
    font-family: var(--h4-heading-font-family);
    font-size: var(--h4-heading-font-size);
    font-style: var(--h4-heading-font-style);
    font-weight: var(--h4-heading-font-weight);
    left: 74px;
    letter-spacing: var(--h4-heading-letter-spacing);
    line-height: var(--h4-heading-line-height);
    position: absolute;
    top: 21px;
    white-space: nowrap;
  }
  .in-element-status-indicators-3 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 52px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    left: 165px;
    padding: 4px 6px;
    position: absolute;
    top: 23px;
    width: 39px;
  }
.glyph-mail-trash {
    height: 36px;
    /* left: 329px; */
    position: absolute;
    top: 15px;
    width: 36px;
  }
  .lineimg{
    background-image: url(http://localhost:3000/assets/img/group-981.png);
    background-size: contain;
    vertical-align: middle;
  }
  .act{
    color: green !important;
  }
  .iact{
    color: red !important;
  }
 .feather-icon-type-4 {
    height: 24px;
    left: 637px;
    position: absolute;
    top: 34px;
    width: 24px;
}
.feather-icon-type-5 {
  height: 24px;
  left: 637px;
  position: absolute;
  top: 156px;
  width: 24px;
}